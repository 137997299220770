import React from "react";
import PropTypes from "prop-types";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Fade from "react-reveal/Fade";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import FeatureSliderWrapper from "./featureSlider.style";
/* import Image1 from "../../images/1.png";
import Image2 from "../../images/2.png";
import Image3 from "../../images/3.png";
import Image4 from "../../images/4.png";
import Image5 from "../../images/5.png";
import Image6 from "../../images/6.png";
import Image7 from "../../images/7.png";
import Image8 from "../../images/8.png";
import Image9 from "../../images/9.png";
import Image10 from "../../images/10.png"; */
import Image1 from "../../images/1.webp";
import Image2 from "../../images/2.webp";
import Image3 from "../../images/3.webp";
import Image4 from "../../images/4.webp";
import Image5 from "../../images/5.webp";
import Image6 from "../../images/6.webp";
import Image7 from "../../images/7.webp";
import Image8 from "../../images/8.webp";
import Image9 from "../../images/9.webp";
import Image10 from "../../images/10.webp";
/* import Image1 from "common/assets/image/OB1.png";
import Image2 from "common/assets/image/OB2.png";
  import Image3 from "common/assets/image/OB3.png";
import Image4 from "common/assets/image/OB4.png";*/
/*import Image2 from 'common/assets/image/ride/mask-2.png';
import Image3 from 'common/assets/image/ride/mask-3.png';
import Image4 from 'common/assets/image/ride/mask-1.png';
import Image5 from 'common/assets/image/ride/mask-3.png'; */

const images = {
  /*  {
    original: `${Image5}`,
    originalAlt: "slide one",
  }, */
  He: [
    {
      original: `${Image6}`,
      originalAlt: "slide two",
    },
    {
      original: `${Image7}`,
      originalAlt: "slide three",
    },
    {
      original: `${Image8}`,
      originalAlt: "slide four",
    },
    {
      original: `${Image9}`,
      originalAlt: "slide five",
    },
    {
      original: `${Image10}`,
      originalAlt: "slide five",
    },
  ],
  En: [
    {
      original: `${Image1}`,
      originalAlt: "slide two",
    },
    {
      original: `${Image2}`,
      originalAlt: "slide three",
    },
    {
      original: `${Image3}`,
      originalAlt: "slide four",
    },
    {
      original: `${Image4}`,
      originalAlt: "slide five",
    },
    {
      original: `${Image5}`,
      originalAlt: "slide five",
    },
  ],
};

const FeatureSlider = ({
  secTitleWrapper,
  secTitle,
  secDescription,
  lang = "He",
}) => {
  const textLang = {
    topTitle: {
      He: "אז איך זה בדיוק עובד?",
      En: "So how exactly does this work? ",
    },
    mainTitle: {
      He: "אפליקציית Dguide תנווט אתכם בין תחנות הסיור ובכל תחנה תפגשו תכנים מרתקים של מיטב המדריכים",
      En: "Dguide app will navigate you between the tour stations and at each station you will receive fascinating content from the best guides ",
    },
    videoBut: { He: "צפה בוידיאו", En: "Watch Trailer" },
  };
  return (
    <FeatureSliderWrapper
      style={{ direction: lang === "He" ? "rtl" : "ltr" }}
      id="feature_slider"
    >
      {/*  <div className="FeatureSliderInner">
        <span> </span>
        <span> </span>
        <span> </span>
      </div> */}
      <Container noGutter mobileGutter width="100%" className="container">
        <Box {...secTitleWrapper}>
          <Fade up>
            <Heading {...secTitle} content={textLang["topTitle"][lang]} />
          </Fade>
          <Fade up>
            <Text {...secDescription} content={textLang["mainTitle"][lang]} />
          </Fade>
        </Box>
        <Box className="FeatureSlider">
          <ImageGallery
            items={images[lang]}
            className="Slider-img"
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={true}
            showBullets={true}
            autoPlay
          />
        </Box>
      </Container>
    </FeatureSliderWrapper>
  );
};

// FeatureSlider style props
FeatureSlider.propTypes = {
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
};

// FeatureSlider default style
FeatureSlider.defaultProps = {
  secTitleWrapper: {
    mb: ["65px", "65px", "70px", "70px", "70px"],
  },
  secTitle: {
    fontSize: ["22px", "26px", "26px", "30px", "36px"],
    fontWeight: "600",
    color: "#e14a1b", //"#15172C",
    lineHeight: "1.34",
    mb: ["15px", "18px", "18px", "20px", "30px"],
    textAlign: "center",
    fontFamily: "Poppins",
  },
  secDescription: {
    fontSize: ["15px", "16px"],
    fontWeight: "400",
    color: "#15172C",
    lineHeight: "1.5",
    mb: "0",
    textAlign: "center",
    width: "300px",
    maxWidth: "100%",
    ml: "auto",
    mr: "auto",
    fontFamily: "Lato",
  },
};

export default FeatureSlider;
