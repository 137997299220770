import React, { Fragment, useState, useEffect } from "react";
//import { useStaticQuery, graphql } from "gatsby";
import Fade from "react-reveal/Fade";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import ProductSlideWrapper, {
  Container,
  CarouselArea,
  CircleLoader,
  //MockupWrapper,
  SectionHeader,
} from "./productSlide.style";
const params = {
  slidesPerView: 5,
  centeredSlides: true,
  spaceBetween: 30,
  loop: true,
  autoplay: {
    delay: 1500,
  },
  grabCursor: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    320: {
      slidesPerView: 1.5,
      spaceBetween: 1,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 1,
    },
    1440: {
      slidesPerView: 5,
      spaceBetween: 1,
    },
  },
};
const ProductSlide = ({ allPics, lang = "He", indexPage = false }) => {
  /*  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        productData {
          slogan
          title
          slider {
            id
            thumb_url {
              publicURL
            }
            link
            title
          }
        }
      }
    }
  `);
  const { slogan, title, slider } = data.appModernJson.productData; */
  const textLang = indexPage
    ? {
        topTitle: { He: "חלק מהשותפים שלנו", En: "Our partners" },
        mainTitle: {
          He: "",
          En: "",
        },
      }
    : {
        topTitle: { He: "טעימה מהסיור", En: "A Taste from the Tour" },
        mainTitle: {
          He: "קצת תמונות מהמסלול ומקומות בהם נבקר",
          En: "Some pictures from the Tour and places we will visit ",
        },
      };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
  }, []);
  return (
    <ProductSlideWrapper>
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading as="h3" content={textLang["topTitle"][lang]} />
            <Heading content={textLang["mainTitle"][lang]} />
          </Fade>
        </SectionHeader>
        <CarouselArea>
          {loading ? (
            <Fragment>
              {/* <MockupWrapper>
                <Image alt="mockup" />
              </MockupWrapper> */}
              <Swiper {...params}>
                {allPics.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div>
                      <Image
                        src={item.url}
                        alt={item.title ? item.title : "profile picture"}
                        props={["borderadius: 8%", "max-height:220px"]}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Fragment>
          ) : (
            <CircleLoader>
              <div className="circle"></div>
              <div className="circle"></div>
            </CircleLoader>
          )}
        </CarouselArea>
        {/* End of carousel section */}
      </Container>
    </ProductSlideWrapper>
  );
};

export default ProductSlide;
